<script>
  import SwiperCore from "swiper";
  // import {
  //   Swiper,
  //   SwiperSlide
  // } from "swiper/vue";
  import "swiper/swiper-bundle.css";

  SwiperCore.use([]);
  // import flatPickr from "vue-flatpickr-component";
  // import {
  //   SimpleBar
  // } from "simplebar-vue3";
  import "flatpickr/dist/flatpickr.css";
  // import {
  //   CountTo
  // } from "vue3-count-to";

  import Layout from "../../../layouts/main.vue";
  import axios from "axios";
  import Swal from "sweetalert2";

  // import Scans from "./scans";

  export default {
    components: {
      // CountTo,
      Layout,
      // Swiper,
      // SwiperSlide,
      // flatPickr,
      // SimpleBar,
      // Scans,
    },
    beforeMount() {
      this.setSelectedPeriod('month')
    },
    data() {
      return {
        monthOffset: 0,
        displayDate: "",
        selectedPeriod: "month",
        selectedTenant: null,
        usergroups: [],
        title: "Licenses",
        items: [{
            text: "Licenses",
            href: "/",
          },
          {
            text: "Licenses",
            active: true,
          },
        ],
        date: null,
      };
    },
    methods: {
      displayBoolean(val) {
        if (val)
          return this.$t('t-yes');
        else
          return this.$t('t-no')
      },
      resetPeriodOffset() {
        this.monthOffset = 0;
        this.updateDisplayDate();
        // this.$refs.scans.setMonthOffset(this.monthOffset);
      },
      previousPeriodOffset() {
        if (this.selectedPeriod === 'month') {
          this.monthOffset--;
        } else {
          this.monthOffset = this.monthOffset - 12;
        }
        this.updateDisplayDate();
        // this.$refs.scans.setMonthOffset(this.monthOffset);
        // this.$refs.scans.getPerformance();
        this.refreshData()
      },
      nextPeriodOffset() {
        if (this.selectedPeriod === 'month') {
          this.monthOffset++;
        } else {
          this.monthOffset = this.monthOffset + 12;
        }
        this.updateDisplayDate();
        // this.$refs.scans.setMonthOffset(this.monthOffset);
        // this.$refs.scans.getPerformance();
        this.refreshData()
      },
      refreshData() {
        // this.$refs.scans.getPerformance();
        // TODO Workaround timeout to prevent 401
        Swal.fire({
          title: this.$t('t-licenses-loading'),
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        setTimeout(() => {
          this.getStatistics();
        }, 100);

      },
      setSelectedPeriod(period) {
        this.resetPeriodOffset();
        this.selectedPeriod = period;
        this.updateDisplayDate();
        this.refreshData()
        // this.$refs.scans.setSelectedPeriod(this.selectedPeriod);
      },
      updateDisplayDate() {
        const date = new Date();
        if (this.selectedPeriod === "month") {
          const pastDate = new Date(date);
          pastDate.setMonth(pastDate.getMonth() + this.monthOffset);
          this.displayDate = pastDate.toLocaleString('default', { month: 'long' }) + ", " + pastDate.getFullYear();
        } else if (this.selectedPeriod === "year") {
          const pastDate = new Date(date);
          pastDate.setMonth(pastDate.getMonth() + this.monthOffset);
          this.displayDate = pastDate.getFullYear();
        }
      },
      toggleTenant(usergroup_id) {
        if (this.selectedTenant) {
          this.selectedTenant = null;
        } else {
          this.selectedTenant = usergroup_id;
        }
      },
      getStatistics() {
        const currentDate = new Date();
        const pastDate = new Date(currentDate);
        pastDate.setMonth(pastDate.getMonth() + this.monthOffset);
        const year = pastDate.getFullYear();
        const month = pastDate.getMonth() + 1;
        const postData = {
          period: this.selectedPeriod,
          year: ""+year,
          month: ""+month,
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/statistic/report', postData).then((data) => {
          Swal.close();
          this.usergroups = data.data.usergroups;
        }).catch((er) => {
          Swal.close();
          console.log(er)
        });
      },
    },
  };
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col">
        <div class="h-100">

          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div>
                  <h4 class="fs-16 mb-1">{{ $t("t-menu-licenses") }}</h4>
                </div>
                <div class="flex-grow-1">
                </div>
                <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div class="row g-3 mb-0 align-items-center">
                      <div>
                        <div class="d-inline cursor-pointer" @click="previousPeriodOffset">
                          <i class="mdi mdi-chevron-left fs-6 me-1"></i>
                        </div>
                        <div class="d-inline">{{displayDate}}</div>
                        <div class="d-inline cursor-pointer" @click="nextPeriodOffset" v-if="monthOffset < 0">
                          <i class="mdi mdi-chevron-right fs-6 ms-1"></i>
                        </div>
                        <button type="button" class="btn btn-sm ms-3 me-1 btn-soft-primary text-dark" v-bind:class="{active: selectedPeriod==='month'}" @click="setSelectedPeriod('month')">
                          {{ $t("t-licenses-month") }}
                        </button>
                        <div class="btn-soft-primary btn btn-sm text-dark" @click="refreshData()">
                          <i class="bx bx-refresh fs-5"></i>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->


          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <!-- Table Nesting -->
                  <table class="table table-nowrap">
                    <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 100px;">{{ $t('t-licenses-customer-id') }}</th>
                      <th scope="col">{{ $t('t-licenses-customer-name') }}</th>
                      <th scope="col" style="width: 120px;">{{ $t('t-licenses-users') }}</th>
                      <th scope="col" style="width: 120px;"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="usergroup in usergroups" :key="usergroup.usergroup_id">
                      <tr class="cursor-pointer" @click="toggleTenant(usergroup.usergroup_id)" :class="{'table-active': selectedTenant === usergroup.usergroup_id}">
                        <td><i class="ri-information-line fs-14 cursor-pointer" v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + usergroup.usergroup_id + '</span>'"></i></td>
                        <td>{{usergroup.usergroup_name}}</td>
                        <td>{{usergroup.total_billable}}</td>
                        <td v-html="selectedTenant === usergroup.usergroup_id ? $t('t-licenses-users-hide') : $t('t-licenses-users-show')"></td>
                      </tr>
                      <tr v-if="selectedTenant === usergroup.usergroup_id" :class="{'table-active': selectedTenant === usergroup.usergroup_id}">
                        <td colspan="4">
                          <table class="table table-nowrap mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">{{ $t('t-licenses-users-id') }}</th>
                              <th scope="col">{{ $t('t-licenses-users-username') }}</th>
                              <th scope="col">{{ $t('t-licenses-users-email') }}</th>
                              <th scope="col">{{ $t('t-licenses-users-licensed') }}</th>
                              <th scope="col">{{ $t('t-licenses-users-active-mailboxes') }}</th>
                              <th scope="col">{{ $t('t-licenses-users-active-agents') }}</th>
                            </tr>
                            </thead>
                            <tbody v-if="usergroup.endusers.length">
                            <tr v-for="enduser in usergroup.endusers" :key="enduser.enduser_id">
                              <td><i class="ri-information-line fs-14 cursor-pointer" v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + enduser.enduser_id + '</span>'"></i></td>
                              <td>{{ enduser.enduser_username }}</td>
                              <td>{{ enduser.enduser_email }}</td>
                              <td>{{ displayBoolean(enduser.billable) }}</td>
                              <td>{{ enduser.total_mailboxes }}</td>
                              <td>{{ enduser.total_endpoints }}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="usergroup.endusers.length === 0">
                            <tr>
                              <td colspan="6">{{ $t('t-licenses-users-none') }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </template>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->

    </div>
  </Layout>
</template>